<div class="upsell-product-box">
  <div class="upsell-product-box-wrapper"justify-content-center padding-horizontal>
    <div col-12 class="product-box_wrapper">
      <div *ngIf="showTitle">
        <div col-12>
            <h4 [innerHTML]="title"></h4>
        </div>
      </div>
      <div class="upsell-product-box-row">
        <div col-12 class="image_wrapper">
            <img src="{{ image }}" data-qa="product-image" alt="{{ imageAlt }}">
            <img src="{{ auxImg }}" alt="{{ auxImgAlt }}" *ngIf="auxImg" class="auxImage">
        </div>
        <div col-12 padding-left class="description_wrapper" [ngClass]="{'hasTitle': showTitle}">
            <p class="subtitle" [innerHTML]="subTitle"></p>
            <p class="regularPrice notranslate">
              <span class="regularPriceText">{{regularText}}: </span> 
              <span class="regularPriceText" data-qa="regular-price">
                <!-- <span *ngIf="showCurrencySymbol" class="currencySymbol">{{ currencySymbol }}</span> -->
                <span class="regularPriceNumber">{{ regularPrice * braintreeCurrencyConversion | dynamicCurrency:currency }}</span> 
                <!-- <span *ngIf="showOfficialCurrency" class="officialCurrency"> {{ officialCurrency }}</span> -->
              </span>
            </p>
            <p class="instantSavings notranslate">
              <span>
                <span class="instantSavingsText">{{instantSavingsText}}: </span> 
                <span data-qa="savings">
                  <!-- <span *ngIf="showCurrencySymbol" class="currencySymbol">{{ currencySymbol }}</span> -->
                  <span class="instantSavingsNumber">{{ instantSavings * braintreeCurrencyConversion | dynamicCurrency:currency }}</span>
                  <!-- <span *ngIf="showOfficialCurrency" class="officialCurrency"> {{ officialCurrency }}</span> -->
                </span>
              </span>
            </p>
            <p class="yourPrice notranslate">
              <span class="yourPriceText">{{yourPriceText}}: </span>
              <span data-qa="price">
                <!-- <span *ngIf="showCurrencySymbol" class="currencySymbol">{{ currencySymbol }}</span> -->
                <span class="yourPriceNumber">{{ yourPrice * braintreeCurrencyConversion | dynamicCurrency:currency }} {{ currency !== 'USD' ? currency : '' }}</span> 
                <!-- <span *ngIf="showOfficialCurrency" class="officialCurrency"> {{ officialCurrency }}</span> -->
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
