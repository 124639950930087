<div class="content" nopadding>
<div class="row">
  <div class="custom-col text-left"> <span class="title"> Secure Ordering </span></div>
  <div class="custom-col text-right"><a class="item_link" (click)="closeWindow()">Close this window</a></div>
  <hr>
</div>
<div class="row">
  <div class="custom-col" col-2> <img src="{{ greenCheckImg }}"> </div>
  <div class="custom-col"><strong> Our site uses technology that ensures your personal and payment information
    is kept confidential and secure. </strong>
</div>

<div class="row">
  <div class="custom-col"><strong>Shopping on our site is safe!</strong></div>
</div>

<div class="row">
  <div class="custom-col" col-8>
    <p>When you provide us with any personal information including your name, address,
      e-mail and credit card number, it is processed over a secure connection on secure
      servers. We use Secure Socket Layers (SSL) to encrypt all information you provide
      before sending it to our servers. SSL is the industry standard and the best software
      available for secure ecommerce transactions.</p>
    <p>The VeriSign (PayPal) Secure Site Seal assures you that this web site has been
      authenticated by PayPal and that confidential transactions with our site are secured
      by SSL encryption.</p>
    </div>
      <div class="custom-col" text-right>
        <img src="{{ validatedImg }}">
        <img src="{{ comodoImg }}">
      </div>
</div>
</div>
