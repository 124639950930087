import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

import { EventStreamerProvider } from '../event-stream';
import { Order } from '../../models/order';
import { FunnelSettingsProvider } from '../funnel-settings';
import { UrlsProvider } from '../urls'

const API_URL = (window as any)["process_env"].API_URL;

const GET_ALL_ORDERS = 'orders/all';
const PAY_WITHOUT_LOGIN = 'orders/createguest';
const CREATE_TRANSACTION_V2 = 'v2/orders/createTransaction';
const PAY_WITH_LOGIN = 'orders/create';
const CREATE_WITH_LOGIN = 'orders/createorder';
const CREATE_WITHOUT_LOGIN = 'orders/setorderguest';
const CREATE_TRANSACTION = 'orders/createtransaction';
const CREATE_PAYPAL_PAYMENT = 'orders/createpaypalpayment';
const EXECUTE_PAYPAL_PAYMENT = 'orders/executepaypalpayment';
const CREATE_PAYPAL_SUBSCRIPTION = 'orders/createpaypalsubscription';
const EXECUTE_PAYPAL_SUBSCRIPTION = 'orders/executepaypalsubscription';
const CANCEL_PAYPAL_SUBSCRIPTION = 'orders/cancelpaypalsubscription';
const DO_EXPRESS_CHECKOUT = 'orders/doexpresscheckout';
const CREATE_RECURRING_PAYMENT = 'orders/createrecurringpayment';
const SET_EXPRESS_CHECKOUT = 'orders/setexpresscheckout';
const GET_EXPRESS_CHECKOUT_DETAIL = 'orders/getexpresscheckoutdetail';
const PAY_WITH_AMAZON = 'orders/payamazon';
const PAY_WITH_AMAZONSUB = 'orders/payamazonsub';
const GET_INFO_AMAZON = 'orders/getInfoAmazon';
const GET_INFO_AMAZONSUB = 'orders/getInfoAmazonSub';
const CAPTURE_AMAZON = 'orders/captureamazon';

@Injectable({ providedIn: 'root' })
export class TouchcrApiOrderProvider {

  constructor(
    public http: HttpClient,
    public eventStreamer: EventStreamerProvider,
    public funnelProvider: FunnelSettingsProvider,
    public urls: UrlsProvider,
  ) {
  }

  getAllOrders() {
    return this.http.get(`${API_URL}${GET_ALL_ORDERS}`)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  payProductWithoutLogin(body:any, v2 = false): Observable<Order> {
    body.statsInfo = this.funnelProvider.getStats();
    const url = !v2 ? `${API_URL}${PAY_WITHOUT_LOGIN}` : `${API_URL.slice(0, -3)}${CREATE_TRANSACTION_V2}`;
    return this.http.post<Order>(url, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  payProduct(body:any) {
    this.eventStreamer.streamEvent('purchase', {
      customTitle: 'Pay now',
      data: body.products,
    });

    body.orderSource = 'Funnel';
    return this.http.post(`${API_URL}${PAY_WITH_LOGIN}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  payUpsells(order:any, upsells:any, type:any, totalTax:any, dataForTax:any) {
    return this.http.post(`${API_URL}${PAY_WITHOUT_LOGIN}`, {
      accId: order.accountId,
      orderType: type,
      totalTax: totalTax,
      parent: order.id,
      orderSource: 'Funnel',
      customerIp: (window as any)['gh']['ip'],
      upsells: upsells,
      dataForTax: dataForTax,
      paymentOptionId: order.paymentOptionId,
      orderTransactionFee: order.transactionFeeAmount,
      transactionFeeDocumentId: order.transactionFeeDocumentId,
      statsInfo: this.funnelProvider.getStats(),
    })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }


  // TOUC-14710 - Braintree
  setBraintreeUpsells(order:any, upsells:any, type:any, paymentMethod:any, totalTax:any) {
    return this.http.post(`${API_URL}${CREATE_WITH_LOGIN}`, {
      accId: order.accountId,
      orderType: type,
      parent: order.id,
      orderSource: 'Funnel',
      customerIp: (window as any)['gh']['ip'],
      upsells: upsells,
      paymentMethod: paymentMethod,
      namedAgent: 'Braintree',
      cardDetails: {},
      totalTax: totalTax,
      statsInfo: this.funnelProvider.getStats(),
    })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }
  payUpsellGoogleAndApplepay(order:any, upsells:any, type:any, token:any, paymentMethod:any) {
    return this.http.post(`${API_URL}${PAY_WITH_LOGIN}`, {
      accId: order.accountId,
      orderType: type,
      parent: order.id,
      orderSource: 'App',
      customerIp: (window as any)['gh']['ip'],
      upsells: upsells,
      paymentMethod: paymentMethod,
      cardDetails: {
        cardId: token
      },
      statsInfo: this.funnelProvider.getStats(),
    })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  setOrderGuest(body:any) {
    if (!body.orderSource) {
      body.orderSource = 'Cart';
    }
    return this.http.post(`${API_URL}${CREATE_WITHOUT_LOGIN}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  setOrder(body:any) {
    if (!body.orderSource) {
      body.orderSource = 'Cart';
    }
    return this.http.post(`${API_URL}${CREATE_WITH_LOGIN}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  setUpsells(order:any, upsells:any, type:any, paymentMethod:any, totalTax:any) {
    return this.http.post(`${API_URL}${CREATE_WITH_LOGIN}`, {
      accId: order.accountId,
      orderType: type,
      parent: order.id,
      orderSource: 'Funnel',
      customerIp: (window as any)['gh']['ip'],
      upsells: upsells,
      paymentMethod: paymentMethod,
      namedAgent: paymentMethod,
      cardDetails: {},
      totalTax: totalTax,
      statsInfo: this.funnelProvider.getStats(),
    })
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  createTransaction(body:any) {
    return this.http.post(`${API_URL}${CREATE_TRANSACTION}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  createPayPalPayment(body:any) {
    return this.http.post(`${API_URL}${CREATE_PAYPAL_PAYMENT}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  executePayPalPayment(body:any) {
    return this.http.post(`${API_URL}${EXECUTE_PAYPAL_PAYMENT}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  createPayPalSubscription(body:any) {
    return this.http.post(`${API_URL}${CREATE_PAYPAL_SUBSCRIPTION}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  executePayPalSubscription(body:any) {
    return this.http.post(`${API_URL}${EXECUTE_PAYPAL_SUBSCRIPTION}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  cancelPayPalSubscription(body:any) {
    return this.http.post(`${API_URL}${CANCEL_PAYPAL_SUBSCRIPTION}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  getExpressCheckoutDetails(body:any) {
    return this.http.post(`${API_URL}${GET_EXPRESS_CHECKOUT_DETAIL}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error);
      });
  }

  setExpressCheckout(body:any) {
    return this.http.post(`${API_URL}${SET_EXPRESS_CHECKOUT}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error);
      });
  }

  doExpressCheckout(body:any) {
    return this.http.post(`${API_URL}${DO_EXPRESS_CHECKOUT}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error);
      });
  }

  createRecurringPayment(body:any) {
    return this.http.post(`${API_URL}${CREATE_RECURRING_PAYMENT}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error);
      });
  }

  payWithAmazon(body:any) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${PAY_WITH_AMAZON}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  payWithAmazonSub(body:any) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${PAY_WITH_AMAZONSUB}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  getInfoAmazon(body:any) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${GET_INFO_AMAZON}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  getInfoAmazonSub(body:any) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${GET_INFO_AMAZONSUB}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }

  captureAmazon(body:any) {
    body.statsInfo = this.funnelProvider.getStats();
    this.urls.clearAmazonParams();
    return this.http.post(`${API_URL}${CAPTURE_AMAZON}`, body)
      .catch((error) => {
        return Observable.throw(error && error.error && error.error.error);
      });
  }
}
