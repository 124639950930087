import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractControl, NgForm, ValidatorFn, Validators } from "@angular/forms";

import { User } from "src/app/models/user";
import { CHECKOUT_LABEL } from "src/constants/constants";

enum FIELDS {
  FIRST_NAME = <any>'firstName',
  LAST_NAME = <any>'lastName',
  PHONE = <any>'phone',
  EMAIL = <any>'email',
  EMAIL_CONFIRM = <any>'confirmEmail',
  STREET = <any>'street',
  ADDITIONAL_BILLING_ADDRESS_LINE = <any>'additionalBillingAddressLine',
  CITY = <any>'city',
  ZIP_POSTAL_CODE = <any>'zipPostalCode',
  COUNTRY = <any>'country',
  STATE = <any>'state'
}

@Component({
  selector: 'account-form',
  templateUrl: './account-form.html',
  styleUrls: ['./account-form.scss']
})

export class AccountFormComponent {

  @ViewChild('accountForm') accountForm: NgForm;

  @Input() guest: User = {} as User;
  @Input() countriesList: Array<any> = [];
  @Input() statesList: Array<any> = [];
  @Input() submittedAccountForm: boolean = false;
  @Input() emailToConfirm: string = '';
  @Input() euSpecified : boolean = false;
  @Input() braintreeDefaultZipPostalCode: string = '';
  @Input() braintreeDefaultCountry: string = 'United States';
  @Input() braintreeDefaultState: string = '';

  @Output() linkForm = new EventEmitter();
  @Output() validateAddressAndCalculateTaxes = new EventEmitter<string>();
  @Output() rememberAccountInformation = new EventEmitter<void>();
  @Output() readyToCreateLead = new EventEmitter<void>();
  @Output() emitConfirmEmail = new EventEmitter<string>();
  @Output() countryChange = new EventEmitter();
  @Output() stateChange = new EventEmitter();

  readonly FIELDS = FIELDS;
  readonly errorImage: string = 'assets/img/icon-warning-pointer.gif';

  emailPattern: string = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  textPattern: string = '^[a-zA-Z¡«¿ßäéíñöüÄÉÍÑÖÜàÀÈèÌìòÒùÙáéíóúýÁÉÍÓÚÝ][a-zA-Z .,-¡«¿ßäéíñöüÄÉÍÑÖÜàÀÈèÌìòÒùÙáéíóúýÁÉÍÓÚÝ]*$';
  mixedPattern: string = '^[a-zA-Z\\d][a-zA-Z\\d .,-¡«¿ßäéíñöüÄÉÍÑÖÜàÀÈèÌìòÒùÙáéíóúýÁÉÍÓÚÝ]*$';
  numberPattern: string = '^[\\d]*$';
  phonePattern: string = '[-+*().,0-9x\/\\# ]*'; // TOUC-1036
  addressPattern: string = '[*#\+,.%()^:;=?[\/A-Za-z0-9 -¡«¿ßäéíñöüÄÉÍÑÖÜàÀÈèÌìòÒùÙáéíóúýÁÉÍÓÚÝ]*' ; // TOUC-1036, DEV-35015 adds german and spanish characters

  isInvalidState: boolean = false;
  firstValidation: boolean = true;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      // EMAIL
      this.accountForm.controls[this.FIELDS.EMAIL].setValidators([
        Validators.required,
        Validators.email,
        this.isValidEmails(this),
        this.isConfirmEmails(this)
      ]);
      this.accountForm.controls[this.FIELDS.EMAIL].updateValueAndValidity();

      // EMAIL_CONFIRM
      this.accountForm.controls[this.FIELDS.EMAIL_CONFIRM].setValidators([
        Validators.required,
        Validators.email,
        this.isValidEmails(this),
        this.isConfirmEmails(this)
      ]);
      this.accountForm.controls[this.FIELDS.EMAIL_CONFIRM].updateValueAndValidity();

      this.linkForm.emit(this.accountForm);

    }, 0);
  }

  getLabel(label: string) {
    try {
      if(this.braintreeDefaultCountry && typeof CHECKOUT_LABEL[this.braintreeDefaultCountry] == 'object' && typeof CHECKOUT_LABEL[this.braintreeDefaultCountry][label] == 'string') return CHECKOUT_LABEL[this.braintreeDefaultCountry][label];
    } catch (e) {
    }
    return CHECKOUT_LABEL['United States'][label];
  }

  validate_AddressAndCalculateTaxes(address: string) {
    this.validateAddressAndCalculateTaxes.emit(address);
  }

  remember_AccountInformation() {
    this.rememberAccountInformation.emit();
  }

  ready_ToCreateLead() {
    this.readyToCreateLead.emit();
  }

  emitConfirmEmailToCheckout() {
    this.emitConfirmEmail.emit(this.emailToConfirm);
  }

  isConfirmEmails(scope:any): ValidatorFn {
    // TODO observed that this function is called twice on blur event of email and confirm email fields - TCR
    // there might be a race condition where this.guest.email is undefined??? only to be defined after blur event of email field???
    //console.log('isConfirmEmails scope', scope);
    //console.log('this.guest scope', this.guest);
    if(!scope.guest.email) return (control: AbstractControl): {[key: string]: any} | null => null;
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (scope.firstValidation) {
        scope.firstValidation = !scope.firstValidation;
        let parent:any = control.parent;
        if (parent.controls[scope.FIELDS.EMAIL] === control) {
          parent.controls[scope.FIELDS.EMAIL_CONFIRM].updateValueAndValidity();
        } else if (parent.controls[scope.FIELDS.EMAIL_CONFIRM] === control) {
          parent.controls[scope.FIELDS.EMAIL].updateValueAndValidity();
        }
        scope.firstValidation = !scope.firstValidation;
      }
      // TODO : Cannot read properties of undefined (reading 'toLowerCase')
      return (
        scope.guest.email &&
        scope.guest.email.toLowerCase() === scope.emailToConfirm.toLowerCase()
      )
        ? null
        : {'invalidConfirmEmails': {value: control.value}};
    };
  }

  isValidEmails(scope:any): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      console.log('isValidEmails scope', scope);
      return (control.value && control.value.match(scope.emailPattern))
        ? null
        : {'invalidValidEmail': {value: control.value}};
    };
  }

  isFacebookApp() {
    if((window as any)['facebookAutofillDisabled'])  {
      return false; // this means the FB auto fill isn't going to be used, like  hen the user clicks the paypal button.
    }
    if((window as any)['facebookAutofillComplete'])  {
      return false; // this means the FB auto fill already ran
    }
    let ua = navigator.userAgent || navigator.vendor || (window as any).opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)

  }


  onCountryChange() {
    this.countryChange.emit(true);
    //this.confirmAddress();
  }
  onStateChange() {
    this.stateChange.emit(true);
    //this.confirmAddress();
  }
  facebookAppStateChangeHandler() {
    console.log('facebookAppStateChangeHandler');
    try {
      let fbcountry:any = (document.querySelector('#FBCountryCode') as any).value;

      if(!fbcountry || fbcountry == '') fbcountry = 'US';
      let FBStates = ((window as any)['countries']).find(  ({ value }:{[key:string]:any}) => value === fbcountry);
      if(!FBStates) return;
      let FBState = FBStates.states;
      (document.querySelector('#facebookstate') as any).value = FBState[(document.querySelector('#FBStateCode') as any).value];
      setTimeout(() => {
        if (this.accountForm.form.controls.additionalBillingAddressLine.value == this.accountForm.form.controls.street.value || this.accountForm.form.controls.additionalBillingAddressLine.value == '') {
          if (document.querySelector('#FBAddressLine2') && (document.querySelector('#FBAddressLine2') as any).value != '') {
            this.accountForm.form.controls.additionalBillingAddressLine.patchValue((document.querySelector('#FBAddressLine2') as any).value);
            this.accountForm.form.controls.additionalBillingAddressLine.markAsDirty();
            this.accountForm.form.controls.additionalBillingAddressLine.updateValueAndValidity();
          }
        } 
      }, 300);

      if(this.accountForm.form.controls.street.value == this.accountForm.form.controls.additionalBillingAddressLine.value) {
        this.accountForm.form.controls.additionalBillingAddressLine.patchValue('');
        this.accountForm.form.controls.additionalBillingAddressLine.markAsDirty();
        this.accountForm.form.controls.additionalBillingAddressLine.updateValueAndValidity();
      }
      if (FBStates.label != '' && FBStates.label != this.accountForm.form.controls.country.value) {
        this.accountForm.form.controls.country.patchValue(FBStates.label);
        this.accountForm.form.controls.country.markAsDirty();
        this.accountForm.form.controls.country.updateValueAndValidity();
        this.accountForm.form.markAsDirty();
       // this.confirmAddress();
       this.validate_AddressAndCalculateTaxes('billing');
       this.onCountryChange();
      }
      setTimeout(() => {
        if (FBState[(document.querySelector('#FBStateCode') as any).value] != '') {
          this.accountForm.form.controls.state.patchValue(FBState[(document.querySelector('#FBStateCode') as any).value]);
          this.accountForm.form.controls.state.markAsDirty();
          this.accountForm.form.controls.state.updateValueAndValidity();
        }
        this.accountForm.form.markAsDirty();
      //  this.confirmAddress();
        this.validate_AddressAndCalculateTaxes('billing');
        this.onStateChange();
      },300)
    } catch (e) {
      console.log('Facebook app state changed', e);
    }
  }

}
