import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output } from "@angular/core";
import { GeneralProvider } from "../../providers/general";

@Component({
  selector: 'header-with-menu',
  templateUrl: './header-with-menu.html',
  styleUrls: ['./header-with-menu.scss'],
})
export class HeaderWithMenuComponent {
  @Input() showLogo: boolean = false;
  @Input() icon: string = 'https://cdn.gundrymd.com/images/gundry-logo.jpg';
  @Input() showContent: boolean = false;
  @Input() phone: string = '';
  @Input() dynamicPhoneNumber: string = ``;
  @Input() displayPhone: boolean = false;
  @Input() hidePhoneOnMobile: boolean = false;
  @Input() phoneIcon: string = '';
  @Input() cartQuantity: number | undefined;
  @Input() braintreeDefaultCountry: string = 'United States';

  isCheckout: boolean = false;

  isMobile: boolean = false;
  /**
   * @apiDescription Used to render menu
   * @apiParam {menu} Array containing menu objects
   * @apiParamExample {menu} Example input Array
   *  [{
   *    label: 'VIP',
   *    target: '_self',
   *    page: 'vip',      //actual page
   *    options: '',
   *    nextPage: false,  //doesn't navigate to next page
   *  }, {
   *    label: 'About Us',
   *    target: '',
   *    page: 'proPlant_offerSelector', //generic URL
   *    options: '',
   *    nextPage: true,   //navigates to next page
   *  }, {
   *    label: 'DROP DOWN MENU',
   *    target: '',
   *    page: '',
   *    options: '',
   *    nextPage: false,
   *    dropDown: [{
   *      label: 'MORE EXAMPLE',
   *      target: '_self',
   *      page: 'https://more.example.com/',
   *      options: '',
   *      nextPage: false,
   *    }, {
   *      label: 'EXAMPLE',
   *      target: '_self',
   *      page: 'https://www.example.com/',
   *      options: '',
   *      nextPage: false,
   *    }],
   *  }]
   */
  @Input() menu: Array<string> = [];
  @Input() displayMenu: boolean = false;
  @Input() hideMenuOnMobile: boolean = false;

  //Set to TRUE if set on order-from page
  @Input() showOnOrderForm: boolean = false;
  @Input() headerText: string = '';

  //Set to TRUE is set on order-success page
  @Input() showOnOrderSuccess: boolean = false;
  @Input() showLine: boolean = false;

  //Set to TRUE if placed on upsell page
  @Input() showOnUpsell: boolean = false;

  //Provide external method to navigate to next page over funnel
  @Output() nextPageEvent = new EventEmitter();

  window: any = window;
  isToggledOff: boolean = true;

  constructor(
    public generalProvider: GeneralProvider,
  ) {
    this.isMobile = this.generalProvider.isMobile();
    if(window.location.pathname.indexOf('SecureCart') > -1) {
      this.isCheckout = true;
    }
  }

  nextPage(page: any) {
    this.nextPageEvent.emit(page);
  }

  toggleMenu() {
    this.isToggledOff = !this.isToggledOff;
  }
}