<div class="headerWithMenu" no-padding>
  <div class="row" [ngClass]="{'rowCheckout': isCheckout}">
    <div
      [class.col-6]="!showOnUpsell"
      [class.col-12]="showOnUpsell"
      [class.col-md-6]="showOnUpsell"
      [class.text-center]="showOnUpsell"
      [class.text-md-left]="showOnUpsell"
      [class.col-lg-3]="showOnOrderSuccess"
      *ngIf="showLogo && icon"
      [ngClass]="{'max-width-50': isCheckout}"
      class="headerWithMenu__col-left"
    >
      <img *ngIf="!isMobile" src="{{ icon }}" alt="logo" width="200" height="52" loading="eager">
      <img *ngIf="isMobile" src="{{ icon }}" alt="logo" width="200" height="52" loading="eager">
    </div>
    <div
      [class.col-6]="showLogo && !showOnUpsell"
      [class.col-md-6]="showLogo && showOnUpsell"
      [class.col-12]="!showLogo && !showOnUpsell || showLogo && showOnUpsell"
      [class.text-right]="showLogo && !showOnUpsell || !showLogo && !showOnOrderSuccess && !showOnUpsell"
      [class.text-center]="!showLogo && showOnOrderSuccess || showLogo && showOnUpsell"
      [class.text-md-right]="showLogo && showOnUpsell"
      *ngIf="showContent"
      class="custom-col headerWithMenu__menu headerWithMenu__col-right"
      [ngClass]="{'max-width-50': isCheckout}"
      [ngClass]="{ 'headerWithMenu__hidden' : showOnOrderSuccess }"
    >
      <ng-container *ngIf="!showOnOrderForm">
        <div class="custom-item headerWithMenu__item headerWithMenu__item--align" *ngIf="displayPhone || displayMenu">
          <div class="phone_content" *ngIf="displayPhone" [ngClass]="{ 'headerWithMenu__hiddenOnMobile' : hidePhoneOnMobile }" item-start>
            <img src="{{ phoneIcon }}" alt=""><span *ngIf="cartQuantity !== undefined" class="custom-thumbnail cart-quantity">{{ cartQuantity }}</span>
          </div>
          <div *ngIf="displayPhone" [ngClass]="{ 'headerWithMenu__hiddenOnMobile' : hidePhoneOnMobile }" [innerHTML]="dynamicPhoneNumber" class="custom-label"></div>
          <div
            *ngIf="displayMenu" text-center
            [ngClass]="{ 'headerWithMenu__hiddenOnMobile' : hideMenuOnMobile && !showOnOrderSuccess }"
            class="custom-icon"
            (click)="toggleMenu()" name="menu" item-end
          ></div>
        </div>
      </ng-container>
      <ng-container *ngIf="displayMenu && !showOnOrderSuccess">
        <ng-container *ngTemplateOutlet="menuList; context: { menu : menu }"></ng-container>
      </ng-container>
      <ng-container *ngIf="showOnOrderForm">
        <div class="headerTextClass" text-uppercase [innerHTML]="headerText"></div>
      </ng-container>
    </div>
    <div col-12 [class.col-lg-9]="showLogo ? true : null" text-center *ngIf="showContent && showOnOrderSuccess" class="custom-col headerWithMenu__menu--inline">
      <ng-container *ngIf="displayMenu">
        <ng-container *ngTemplateOutlet="menuList; context: { menu : menu }"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #menuList let-menu="menu">
  <div [ngClass]="{ 'headerWithMenu__hiddenOnMobile' : isToggledOff && showOnOrderSuccess }" [hidden]="isToggledOff && !showOnOrderSuccess" class="custom-list">
    <ng-container *ngFor="let item of menu">
      <div tappable *ngIf="!item.dropDown" (click)="item.nextPage ? nextPage(item.page) : window.open(item.page, item.target, item.options)" class="custom-item">
        <div [class.text-right]="!showOnOrderSuccess ? true : null" [attr.text-center]="showOnOrderSuccess ? true : null" [innerHTML]="item.label" class="custom-label"></div>
      </div>
      <div *ngIf="item.dropDown" class="headerWithMenu__dropDown">
        <div tappable class="custom-item">
          <div [class.text-right]="!showOnOrderSuccess ? true : null" [attr.text-center]="showOnOrderSuccess ? true : null" [innerHTML]="item.label" class="custom-label">
          </div>
        </div>
        <ng-container *ngTemplateOutlet="dropDown; context: { menu : item }"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #dropDown let-menu="menu">
  <div class="custom-list">
    <ng-container *ngFor="let item of menu.dropDown">
      <div tappable (click)="item.nextPage ? nextPage(item.page) : window.open(item.page, item.target, item.options)" class="custom-item">
        <div [attr.text-right]="!showOnOrderSuccess ? true : null" [attr.text-center]="showOnOrderSuccess ? true : null" [innerHTML]="item.label" class="custom-label"></div>
      </div>
    </ng-container>
  </div>
</ng-template>
<div *ngIf="!isCheckout" class="header-with-menu_padding"></div>