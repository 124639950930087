import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Pipe({
  name: 'dynamicCurrency',
  standalone: false
})
export class DynamicCurrencyPipe implements PipeTransform { 

  transform(value: number, currencyCode: string, locale?: string): string {
    const symbol = getCurrencySymbol(currencyCode, 'narrow', locale);

    switch (currencyCode) {
      case 'CAD':
        return `${symbol}${value.toFixed(2)}`;
      case 'GBP':
        return `${symbol}${value.toFixed(2)}`; //multiply by tax rate here?
      case 'AUD':
        return `${symbol}${value.toFixed(2)}`; //multiply by tax rate here?
      default:
        return `${symbol}${value.toFixed(2)}`;
    }
  }
}
