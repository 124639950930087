import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicCurrencyPipe } from './pipes/dynamic-currency.pipe';

@NgModule({
  declarations: [DynamicCurrencyPipe],
  exports: [DynamicCurrencyPipe],
  imports: [CommonModule],
  providers: [DynamicCurrencyPipe] 
})
export class SharedModule {}